.slide-it {
  transform: translateX(0px);
  opacity: 1;
  transition: all 0.25s ease-out;
}
.slide-it.htmx-swapping {
  transform: translateX(-90px);
  opacity: 0;
  transition: all 0.25s ease-out;
}

.htmx-request .htmx-indicator {
  display: inline;
}
.htmx-request.htmx-indicator {
  display: inline;
}

#loading_popin {
  opacity: 1 !important;
  .gloader {
    display: none;
    border: 4px solid var(--bs-primary);
  }
  &.htmx-request {
    .gloader {
      display: block;
    }
    #footer_login_modal_content {
      opacity: 0.3;
    }
  }
  .alert {
    line-height: 1.2;
  }
}

.dashboard-content {
  .svg-bg {
    &.avatar {
      min-width: 3rem;
      min-height: 3rem;
    }
  }
}
#addresses_container,
#form_account_container,
#form_account_delete,
#customize_swap {
  .invalid-field {
    border: 1px dashed $danger;
  }
  .error {
    font-size: 12px;
    color: $danger;
    margin-top: 5px;
    font-weight: 500;
    padding-left: 1rem;
  }
}
#form_account_delete .error,
#customize_swap .error {
  padding-left: 0;
}

.account-page {
  .wrapper.generic-header {
    margin-bottom: -3rem !important;
    .container {
      padding-bottom: 4.5rem !important;
      // padding-top: 2rem !important;
      padding-top: 0 !important;
      text-align: left !important;
      h1 {
        font-size: 1.8rem !important;
        line-height: 1.3 !important;
      }
      .row > * {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
      }
    }
    & + .account-wrapper {
      padding-top: 0 !important;
      background: $light;
      height: 100%;
    }
  }
}

.account-wrapper {
  .navbar-vertical {
    .nav-item {
      width: 100%;
    }
    .card .nav-tabs {
      border: none;
      .nav-link {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        padding: 0.4rem 1.3rem;
        padding-right: 0;
        padding-left: calc(var(--bs-card-spacer-x) - 0.4rem);
        margin-left: calc(var(--bs-card-spacer-x) * -1);
        border: none;
        border-radius: 0;
        i {
          opacity: 0.7;
        }
        &.active {
          padding-left: calc(var(--bs-card-spacer-x) - 0.6rem);
          i {
            opacity: 1;
          }
          isolation: auto;
          border-left: solid 3px $primary;
        }
        &.text-danger {
          &:hover {
            color: darken($danger, 10%) !important;
          }
        }
      }
    }
  }
}

//----------------------------------------------------------//
//	BLOG
//----------------------------------------------------------//
.classic-view .post {
  margin-bottom: 2rem;
}
.classic-view .post-header {
  margin-bottom: 0.9rem;
}
.blog.single .post {
  margin-bottom: 0;
}
.post-content {
  position: relative;
}
.card-event {
  @media (max-width: 767px) {
    width: 100%;
  }
  .post-content-tags {
    font-weight: 600;
  }
}
.blog article {
  .post-content {
    p {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }
}
.blog.grid-view article {
  &.focus {
    margin-bottom: 4rem;
    .swiper-container .swiper {
      figure span.img {
        &:after {
          padding-bottom: 65%;
        }
      }
    }
    .post-content {
      p {
        font-size: 16px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }
  a.card {
    &.no-card {
      border: none;
      border-radius: 0;
      box-shadow: none;
      figure span.img {
        &:after {
          padding-bottom: 60%;
        }
      }
      .card-body,
      .card-footer {
        padding: 0;
        border: none;
      }
    }
    .post-title {
      @media (max-width: 1399px) {
        font-size: 1rem !important;
        line-height: 1.4;
      }
    }
    .post-category span,
    p {
      color: var(--bs-body-color) !important;
    }

    &:hover {
      span.hover:before {
        transform-origin: 0 50%;
        transform: scale3d(1, 1, 1);
        transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
      }

      .overlay:not(.overlay-3) span.bg {
        opacity: 1;
        transition: opacity 0.35s ease-in-out;
      }
      .hover-scale img {
        transform: scale(1.05);
      }
      .overlay-1 .figcaption,
      .overlay-1 figcaption {
        opacity: 1;
      }
      .overlay-1 .figcaption *,
      .overlay-1 figcaption * {
        transform: translateY(-50%);
      }
    }
  }
  span {
    display: block;
    &.hover {
      display: inline;
    }
  }
  .text-line {
    font-size: 13px;
    padding-left: 1rem;
    line-height: 1.4;
    margin-bottom: 0.8rem;
    @media (max-width: 1399px) {
      font-size: 12px;
    }
    &:before {
      top: 8px;
      left: 0;
      width: 0.5rem;
      height: 0.05rem;
      transform: none;
    }
  }
  figure span.d-date {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background: $primary;
    color: $white;
    padding: 8px 12px;
    font-weight: 600;
    line-height: 1;
    border-radius: $border-radius;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    .d-from,
    .d-to {
      font-size: 11px !important;
      text-transform: uppercase;
    }
    .d-to {
      margin: 8px 0;
    }
    .d-dd-range {
      display: flex;
      align-items: center;
      font-size: 22px;
      .d-to {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    .d-dm {
      font-size: 16px;
      text-transform: uppercase;
      margin: 3px 0;
    }
    .d-yy {
      font-size: 26px;
      font-weight: 900;
      margin-top: 5px;
    }
    .d-dd {
      font-size: 22px;
      font-weight: 900;
      margin-top: 5px;
    }
    .d-mm {
      font-size: 16px;
      text-transform: uppercase;
      margin-top: 4px;
    }
    &.range {
      .d-from {
        margin-bottom: 4px;
      }
    }
    &.single {
      .d-dd {
        font-size: 36px;
        font-weight: 900;
        margin-top: 5px;
      }
      .d-mm {
        font-size: 18px;
        text-transform: uppercase;
        margin-top: 3px;
        margin-bottom: 4px;
      }
    }
  }
  figure span.img {
    img {
      @include imageBg();
      position: absolute !important;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-bottom: 70%;
    }
    &.event {
      &:after {
        content: "";
        display: block;
        padding-bottom: 85%;
      }
      @media (max-width: 767px) {
        &:after {
          content: "";
          display: block;
          padding-bottom: 65%;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .classic-view article .card-body {
    padding: 2rem 2.5rem 1.25rem;
  }
  .classic-view article .card-footer {
    padding: 1.25rem 2.5rem 1.25rem;
  }
  .blog.single .card-body {
    padding: 2.8rem 3rem 2.8rem;
  }
  .grid-view article .card-body {
    padding: 1.2rem 1.4rem 1.2rem 1.4rem;
  }
  .grid-view article .card-footer {
    padding: 1rem 1.75rem 1rem;
  }
}
@media (max-width: 767px) {
  .classic-view article .card-body,
  .grid-view article .card-body {
    padding-bottom: 1rem;
  }
}
//----------------------------------------------------------//
//	WIDGETS
//----------------------------------------------------------//
aside:not(.doc-sidebar) .widget + .widget {
  margin-top: 2rem;
}
footer [class*="col-"] .widget + .widget {
  margin-top: 1rem;
}
//----------------------------------------------------------//
//	SIDEBAR
//----------------------------------------------------------//
.sticky-sidebar {
  position: sticky;
  top: 0;
  height: 100%;
}
.sidebar nav .nav-link {
  font-weight: $font-weight-base;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  &.active {
    color: var(--#{$prefix}primary) !important;
  }
}
//----------------------------------------------------------//
//	COMMENTS
//----------------------------------------------------------//
#comments {
  margin: 0;
  position: relative;
  ol.commentlist {
    list-style: none;
    margin: 0;
    padding: 0;
    li.comment {
      margin-top: 2rem;
      &:first-child {
        margin: 0;
      }
    }
  }
  ul.children {
    margin: 2rem 0 0 0;
    overflow: inherit;
    padding: 0 0 0 3.5rem;
    list-style: none;
    li.comment {
      position: relative;
      &:before {
        display: block;
        font-family: $font-family-custom-icons;
        content: $icon-line;
        @include font-size(2rem);
        position: absolute;
        left: -2.5rem;
        top: -1.25rem;
        color: rgba($border-light, 0.25);
      }
    }
  }
  .comment-author,
  .post-meta {
    margin: 0;
  }
  .comment-author {
    margin-bottom: 0.2rem;
  }
}
.author-info h6 {
  margin-bottom: 0.2rem;
}
#comments {
  .comment-header {
    margin-bottom: 0.5rem;
  }
  .post-meta li:before {
    margin: 0 0.6rem 0 0.4rem;
  }
}
.user-avatar {
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  position: relative;
  border-radius: 100%;
}
@media (max-width: 767px) {
  #comments {
    .user-avatar {
      display: none;
    }
    ul.children {
      padding-left: 1.5rem;
      li.comment:before {
        display: none;
      }
    }
  }
}

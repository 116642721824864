//----------------------------------------------------------//
//	CARET
//----------------------------------------------------------//
@mixin caret-down {
  border: 0;
  content: $icon-caret-down;
}
@mixin caret-up {
  border: 0;
  content: $icon-caret-up;
}
@mixin caret-end {
  border: 0;
  content: $icon-caret-end;
}
@mixin caret-start {
  border: 0;
  content: $icon-caret-start;
}
@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      font-family: $font-family-unicons;
      display: inline-block;
      margin-left: $caret-spacing;
      vertical-align: $caret-vertical-align;
      font-weight: 700 !important;
      content: "";
      @if $direction == down {
        @include caret-down();
      } @else if $direction == up {
        @include caret-up();
      } @else if $direction == end {
        @include caret-end();
      } @else if $direction == start {
        @include caret-start();
      }
    }
    &:empty::after {
      margin-left: 0;
    }
  }
}
//----------------------------------------------------------//
//	SHAPES
//----------------------------------------------------------//
@mixin bg-dot-variant($color) {
  background-image: radial-gradient($color 2px, transparent 2.5px);
}
@mixin bg-line-variant($color) {
  background: repeating-linear-gradient(
    -55deg,
    rgba(var(--#{$prefix}white-rgb), 0) 0.8px,
    $color 1.6px,
    $color 3px,
    rgba(var(--#{$prefix}white-rgb), 0) 3.8px,
    rgba(var(--#{$prefix}white-rgb), 0) 10px
  );
}

@mixin imageBg() {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@mixin hoverBtn($invert: false) {
  position: relative;
  &::before {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
  }
  &::before {
    content: "";
    @if $invert == true {
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
      transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
    } @else {
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    }
  }
  &:hover:before {
    @if $invert == true {
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    } @else {
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
      transition-timing-function: cubic-bezier(0.4, 1, 0.8, 1);
    }
  }
}

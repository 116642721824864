header,
.navbar-clone {
  .logo {
    max-width: 180px;
    max-height: 50px;
  }
}

.navbar:not(.navbar-clone) {
  .logo-scroll {
    display: none;
  }
}

header,
.navbar-clone {
  .center-logo .logo {
    min-width: 120px;
    //max-height: 105px;
  }
}
.content-wrapper .logo.logo-large {
  max-width: 250px !important;
}
.generic-header-title {
  .inner {
    border-bottom: solid 1px rgba(#cacaca, 0.6);
  }
}

.border-header {
  border-bottom: solid 1px rgba(#cacaca, 0.6);
}

header.bordered-header {
  z-index: 20;
  @media (max-width: 991.98px) {
    z-index: 1030;
  }
  & + .section-breadcrumb {
    margin-top: -1px;
    position: relative;
    z-index: 21;
    @media (max-width: 991.98px) {
      z-index: 1031;
    }
  }
}

header.position-absolute {
  & + section.wrapper.header-height-small {
    padding-top: 10rem;
  }
  & + section.wrapper.header-height-medium {
    padding-top: 7rem;
  }
  & + section.wrapper.header-height-large {
    padding-top: 7rem;
  }
  &.boxed {
    & + section.wrapper.header-height-small {
      padding-top: 5rem;
      &.generic-header {
        padding-top: 2rem;
      }
    }
    & + section.wrapper.header-height-medium {
      padding-top: 3rem;
      &.generic-header {
        padding-top: 2rem;
      }
    }
    & + section.wrapper.header-height-large {
      padding-top: 4rem;
      &.generic-header {
        padding-top: 3rem;
      }
    }
  }
}

header.regular-transparent {
  & + section.wrapper.header-height-small {
    padding-top: 4rem;
  }
  & + section.wrapper.header-height-medium {
    padding-top: 7rem;
  }
  & + section.wrapper.header-height-large {
    padding-top: 7rem;
  }
}

// header.bg-transparent.header-extra-margin:not(.standard) {
//   & + section.wrapper:not(.section-header-slider):not(.section-breadcrumb) {
//     padding-top: 12rem !important;
//     min-height: 35rem !important;
//     &.header-height-small {
//       min-height: 20rem !important;
//     }
//     &.header-breadcrumb-boxed {
//       padding-top: 8rem !important;
//       min-height: 0 !important;
//     }
//   }
//   & + section.wrapper.section-breadcrumb {
//     margin-top: 8.5rem !important;
//     position: relative;
//   }
// }
// header.bg-transparent.header-extra-margin.standard {
//   & + section.wrapper:not(.section-breadcrumb):not(.header-breadcrumb-boxed) {
//     padding-top: 11rem !important;
//     min-height: 0 !important;
//   }
//   & + section.wrapper.header-breadcrumb-boxed {
//     margin-top: 4.5rem !important;
//     position: relative;
//   }
//   &.pb-8 {
//     & + section.wrapper.header-breadcrumb-boxed {
//       margin-top: 4.5rem !important;
//     }
//   }
//   & + section.wrapper.section-breadcrumb {
//     margin-top: 8.5rem !important;
//     position: relative;
//   }
// }

.navbar-clone.extended {
  .navbar-collapse-wrapper {
    border-bottom: 0 !important;
  }
  .offcanvas-body {
    justify-content: start !important;
    .navbar-brand {
      display: block !important;
    }
    .language-select {
      display: list-item !important;
    }
  }
}
@media (max-width: 991.98px) {
  .extended .topbar {
    border-bottom: 0 !important;
  }
}

.navbar.extended {
  &.navbar-light {
    .bordered {
      border-bottom: solid 1px rgba(#cacaca, 0.6);
    }
  }
  .bordered {
    border-bottom: solid 1px rgba(#eee, 0.5);
  }
}
.content-wrapper .offcanvas-header .logo {
  max-width: 170px;
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-other .nav-item.language-select .nav-link {
    font-size: inherit;
  }
}
.navbar-expand-lg.navbar-light
  .navbar-dark
  .dropdown:not(.dropdown-submenu)
  > .dropdown-toggle:after {
  color: $white;
}

.hr-container:not(.no-border) {
  @extend .container;
  height: 1px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: rgba(#cacaca, 0.6);
    z-index: 0;
  }
  & + section.section-breadcrumb {
    margin-top: -1px;
  }
}
.hr-container.no-border {
  margin-top: -1px;
}

//----------------------------------------------------------//
//	STICKY FOOTER
//----------------------------------------------------------//
html,
body {
  height: 100%;
}
body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.content-wrapper,
footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  .logo {
    width: 100%;
    max-width: 200px;
    max-height: 105px;
  }
}
footer {
  margin-top: auto;
  a {
    color: var(--bs-nav-link-color);
  }
}
.content-wrapper {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.footer-copyright {
  padding: 0 0 15px 0;
  font-size: 13px;
  em {
    font-style: normal;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    &:before {
      content: "-";
      margin: 0 0 0 4px;
    }
  }
}

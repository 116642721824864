//----------------------------------------------------------//
//	CONTROLS
//----------------------------------------------------------//
.swiper-controls {
  position: absolute !important;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .swiper-navigation {
    pointer-events: all;
    .swiper-button {
      background: rgba(var(--#{$prefix}white-rgb), 0.7);
      color: $main-dark;
      border: 0;
      box-shadow: $box-shadow-sm;
      width: 2.2rem;
      height: 2.2rem;
      line-height: inherit;
      border-radius: 100%;
      text-shadow: none;
      @include transition($link-transition);
      &:hover {
        background: rgba(var(--#{$prefix}white-rgb), 0.9);
      }
      &:focus {
        outline: 0;
      }
      &:after {
        font-family: $font-family-unicons;
        @include font-size(1.2rem);
      }
      &.swiper-button-prev {
        left: 1rem;
        &:after {
          content: $icon-prev;
        }
      }
      &.swiper-button-next {
        right: 1rem;
        &:after {
          content: $icon-next;
        }
      }
      &.swiper-button-disabled {
        background: rgba(var(--#{$prefix}white-rgb), 0.7);
      }
    }
  }
  .swiper-pagination {
    bottom: -2.8rem;
    position: absolute !important;
    pointer-events: all;
    .swiper-pagination-bullet {
      -webkit-backface-visibility: visible;
      @include transition($link-transition);
      position: relative;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0;
      padding: 0;
      background: $primary;
      opacity: 0.5;
      border-radius: 100%;
      border: 2px solid transparent;
      transform: scale(0.6);
      margin-right: 5px;
      &:hover {
        transform: scale(1);
      }
      &:focus {
        outline: 0;
      }
      &.swiper-pagination-bullet-active {
        transform: scale(1);
        background: none;
        border-color: $primary;
        &:after {
          width: 4px;
          height: 4px;
          transform: translate(-50%, -50%);

          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          background-color: $primary;
          border-radius: 50%;
        }
      }
    }
  }
}

.text-invert,
.text-dot-invert {
  .swiper-controls
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border-color: $white;
  }
  .swiper-controls .swiper-pagination .swiper-pagination-bullet {
    background: $white;
  }
}

.swiper-container {
  -webkit-user-select: none;
  position: relative;
  z-index: 10 !important;
  &.clients .swiper {
    display: flex;
    align-items: center;
  }
  .swiper {
    img {
      width: 100%;
      height: auto;
    }
  }
  &.nav-color .swiper-button,
  &.nav-color .swiper-slide figure .item-link {
    background: rgba(var(--#{$prefix}primary-rgb), 0.9) !important;
    color: var(--#{$prefix}white) !important;
    &.swiper-button-disabled {
      background: rgba(var(--#{$prefix}primary-rgb), 0.7) !important;
    }
    &:hover {
      background: var(--#{$prefix}primary) !important;
    }
  }
  &.nav-dark .swiper-button,
  &.nav-dark .swiper-slide figure .item-link {
    background: $lightbox-icon-bg !important;
    color: $lightbox-icon-color !important;
    &.swiper-button-disabled {
      background: rgba($black, 0.1) !important;
    }
    &:hover {
      background: $lightbox-icon-hover-bg !important;
    }
  }
  &.nav-bottom {
    .swiper-navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      transform: none;
      position: absolute !important;
      bottom: -3.2rem;
      left: 0;
      width: 100%;
      .swiper-button {
        top: auto;
        left: auto;
        right: auto;
        bottom: 0;
        position: relative;
        margin: 0 0.2rem;
      }
      + .swiper-pagination {
        bottom: -5.2rem;
      }
    }
    &.nav-far .swiper-navigation {
      bottom: -4.1rem;
    }
    &.nav-start .swiper-navigation {
      text-align: left;
      justify-content: flex-start;
      .swiper-button {
        margin: 0 0.4rem 0 0;
      }
    }
  }
  &.dots-over {
    .swiper-pagination {
      bottom: 0.6rem;
      .swiper-pagination-bullet {
        background: var(--#{$prefix}white);
        opacity: 1;
        box-shadow: $box-shadow-sm;
        &.swiper-pagination-bullet-active {
          background: none;
          border-color: var(--#{$prefix}white);
        }
      }
    }
  }
  &.dots-start .swiper-pagination {
    text-align: left;
    justify-content: flex-start;
  }
  &.dots-light .swiper-pagination .swiper-pagination-bullet {
    background: var(--#{$prefix}white);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: none;
      border-color: var(--#{$prefix}white);
    }
  }
  &.dots-dark .swiper-pagination .swiper-pagination-bullet {
    background: $lightbox-icon-bg;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: none;
      border-color: $lightbox-icon-bg;
    }
  }
  &.dots-light.dots-light-75 .swiper-pagination .swiper-pagination-bullet {
    opacity: 0.75;
  }
  &.dots-closer .swiper-pagination {
    bottom: -2rem;
  }
}
//----------------------------------------------------------//
//	OVERLAY
//----------------------------------------------------------//
.swiper-slide {
  &.bg-overlay:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba($gray-900, 0.5);
  }
  &.rounded.bg-overlay:before {
    border-radius: $border-radius;
  }
  &.bg-overlay-300:before {
    background: rgba($gray-900, 0.3);
  }
  &.bg-overlay-400:before {
    background: rgba($gray-900, 0.4);
  }
  &.bg-overlay [class*="container"] > *,
  &.bg-overlay .caption-wrapper > * {
    position: relative;
    z-index: 3;
  }
  .caption-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    .caption {
      display: flex;
    }
  }
}
@media (max-width: 767px) {
  .caption-wrapper {
    display: none !important;
  }
}
//----------------------------------------------------------//
//	HERO
//----------------------------------------------------------//
.header-height-small {
  .swiper-hero,
  .swiper-hero .swiper-slide {
    height: auto !important;
    min-height: 15rem;
  }
}
.header-height-full {
  .swiper-hero,
  .swiper-hero .swiper-slide {
    height: 100vh !important;
    @media (max-height: 649px) {
      min-height: 32rem;
    }
  }
}
.swiper-hero,
.swiper-hero .swiper-slide {
  height: $hero-slider-height !important;
}
.swiper-hero {
  .swiper-slide {
    background-size: cover;
    background-position: center center;
  }
}
@media (max-width: 767px) {
  .swiper-hero,
  .swiper-hero .swiper-slide {
    height: $hero-slider-height-mobile !important;
  }
  .swiper-hero .swiper-navigation {
    display: none;
  }
}
.swiper-slide.cover {
  .swiper-inner {
    position: relative;
    z-index: 4;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    @include imageBg();
    height: 100% !important;
  }
}
//----------------------------------------------------------//
//	FULLSCREEN
//----------------------------------------------------------//
.swiper-fullscreen {
  height: 100vh;
  .swiper:not(.swiper-thumbs) .swiper-slide {
    height: 100vh;
    background-size: cover;
    background-position: center center;
  }
  .swiper-thumbs {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    .swiper-slide {
      cursor: pointer;
      width: 2.5rem !important;
      height: 2.5rem !important;
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        z-index: 2;
        transition: all 150ms ease-in-out;
        box-sizing: border-box;
        border: 2px solid transparent;
        border-radius: 100%;
      }
      &.swiper-slide-thumb-active:after {
        border-color: rgba(var(--#{$prefix}white-rgb), 0.6);
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .swiper-fullscreen,
  .swiper-fullscreen .swiper:not(.swiper-thumbs) .swiper-slide {
    height: $hero-slider-height !important;
  }
}
@media (max-width: 767px) {
  .swiper-fullscreen,
  .swiper-fullscreen .swiper:not(.swiper-thumbs) .swiper-slide {
    height: $hero-slider-height-mobile !important;
  }
}
//----------------------------------------------------------//
//	AUTO
//----------------------------------------------------------//
@media (min-width: 1024px) {
  .swiper-auto .swiper-slide {
    width: 850px;
  }
  .swiper-auto.swiper-auto-xs .swiper-slide {
    width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .swiper-auto .swiper-slide {
    width: 600px;
  }
  .swiper-auto.swiper-auto-xs .swiper-slide {
    width: 400px;
  }
}
@media (max-width: 767px) {
  .swiper-auto.swiper-auto-xs .swiper-slide {
    width: 300px;
  }
}
//----------------------------------------------------------//
//	ANIMATIONS
//----------------------------------------------------------//
.swiper-container .swiper-slide [class*="animate__"] {
  animation-name: none;
  visibility: hidden;
}
.swiper-container .swiper-slide.swiper-slide-active {
  [class*="animate__"] {
    visibility: visible;
    &.animate__fadeInUp {
      animation-name: fadeInUp;
    }
    &.animate__fadeInDown {
      animation-name: fadeInDown;
    }
    &.animate__fadeInTopRight {
      animation-name: fadeInTopRight;
    }
    &.animate__slideInUp {
      animation-name: slideInUp;
    }
    &.animate__slideInDown {
      animation-name: slideInDown;
    }
    &.animate__slideInLeft {
      animation-name: slideInLeft;
    }
    &.animate__slideInRight {
      animation-name: slideInRight;
    }
    &.animate__zoomIn {
      animation-name: zoomIn;
    }
  }
}
//----------------------------------------------------------//
//	INNER
//----------------------------------------------------------//
.item-inner {
  padding: 1rem 0.75rem;
}
//----------------------------------------------------------//
//	THUMBNAILS
//----------------------------------------------------------//
.swiper-thumbs-container:not(.swiper-fullscreen) {
  .swiper-main {
    position: relative;
  }
  .swiper:not(.swiper-thumbs) {
    margin-bottom: 0.5rem;
  }
  &:not(.swiper-fullscreen) .swiper-thumbs {
    .swiper-slide {
      cursor: pointer;
      border: solid 4px transparent;
      border-radius: $border-radius * 2;
      overflow: hidden;
      img {
        border-radius: calc(4px - ($border-radius * 2)) !important;
      }
      &.swiper-slide-thumb-active {
        border-color: rgba($navy, 0.3);
      }
    }
  }
}
//----------------------------------------------------------//
//	STATIC CAPTIONS
//----------------------------------------------------------//
.swiper-static {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
}
@media (min-width: 1400px) {
  .container-custom {
    max-width: 80%;
  }
}
@media (min-width: 1200px) {
  .container-custom {
    max-width: 90%;
  }
}
//----------------------------------------------------------//
//	TICKER MODE
//----------------------------------------------------------//
.swiper-wrapper.ticker {
  transition-timing-function: linear !important;
}

.slider-blog-wrapper {
  article figure span {
    display: block;
  }
  article figure span.img {
    position: relative;
  }
  article figure span.img:after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }
  article figure span.img img {
    @include imageBg();
    position: absolute !important;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  .swiper-slide {
    height: auto;
    .item-inner {
      height: 100%;
    }
  }
}

.swiper-container {
  .swiper-wrapper {
    &.hauto {
      .swiper-slide {
        height: auto;
        .item-inner {
          height: 100%;
          .card {
            height: 100%;
          }
        }
      }
    }
  }
}

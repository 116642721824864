.content-wrapper {
  transition: opacity 0.35s ease;
  // DEBUG
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // DEBUG
  &.loading {
    opacity: 0;
  }
}

//----------------------------------------------------------//
//	TITLES
//----------------------------------------------------------//
h1,
h2,
h3,
h4,
h5 {
  font-weight: 600 !important;
  &.section-title {
    u {
      text-decoration: none;
      position: relative;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        display: block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        left: 50%;
        bottom: -0.1em;
        width: 110%;
        height: 0.3em;
        transform: translateX(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='#{url-color($primary)}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E");
      }
    }
    strike {
      text-decoration: none;
      position: relative;
      z-index: 1;
      display: inline-block;
      &:before {
        content: "";
        z-index: -1;
        border: 3px solid var(--bs-primary);
        position: absolute;
        left: 50%;
        top: 52%;
        opacity: 1;
        border-radius: 80%;
        transform: translate(-50%, -50%) rotate(-1deg);
        border-top-width: 0;
        width: 111%;
        height: 110%;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        display: block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        left: 50%;
        bottom: -0.1em;
        border: 3px solid var(--bs-primary);
        top: 52%;
        opacity: 1;
        border-radius: 80%;
        transform: translate(-50%, -50%) rotate(-2deg);
        border-left-width: 0;
        border-bottom-width: 0;
        width: 107%;
        height: 111%;
      }
    }
    b,
    strong {
      color: $primary;
    }
  }
}

//----------------------------------------------------------//
//	WRAPPERS
//----------------------------------------------------------//
.wrapper-border {
  border-bottom: 1px solid $border-light;
  &.bg-dark {
    border-color: rgba(var(--#{$prefix}white-rgb), 0.08);
  }
}
//----------------------------------------------------------//
//	BACKGROUND IMAGE
//----------------------------------------------------------//
.image-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 0;
  &:not(.mobile) {
    background-attachment: fixed !important;
  }
  &.bg-auto {
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }
  &.bg-full {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }
  &.image-bg {
    img {
      position: absolute;
      top: 0;
      left: 0;
      @include imageBg();
      z-index: 0 !important;
    }
    &.bg-overlay:not(.bg-text):before {
      z-index: 11;
      background: linear-gradient(
        180deg,
        rgba($gray-900, 0.4) 0%,
        rgba($gray-900, 0) 40%
      );
    }
  }
  &.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }
  &.bg-overlay:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($gray-900, 0.5);
  }
  &.bg-overlay-300:before {
    background: rgba($gray-900, 0.3);
  }
  &.bg-overlay-400:before {
    background: rgba($gray-900, 0.4);
  }
  &.bg-overlay-light-300:before {
    background: rgba(var(--#{$prefix}white-rgb), 0.3);
  }
  &.bg-overlay-light-400:before {
    background: rgba(var(--#{$prefix}white-rgb), 0.4);
  }
  &.bg-overlay-light-500:before {
    background: rgba(var(--#{$prefix}white-rgb), 0.5);
  }
  &.bg-overlay-light-600:before {
    background: rgba(var(--#{$prefix}white-rgb), 0.6);
  }
  &.bg-overlay:not(.bg-content) div,
  &.bg-overlay.bg-content .content {
    position: relative;
    z-index: 2;
  }
  &.bg-overlay .form-floating > label {
    position: absolute;
  }
  &.card:before {
    border-radius: $border-radius !important;
  }
}
@media (max-width: 991.98px) {
  .image-wrapper.bg-full {
    background-size: cover !important;
  }
}
@media (max-width: 767px) {
  .bg-map {
    background: none !important;
  }
}
@media (max-width: 575.98px) {
  .bg-xs-none {
    background-image: none !important;
  }
}

@media (max-width: 991.98px) {
  .image-wrapper.bg-cover {
    min-height: 25rem;
  }
}
@media (max-width: 767px) {
  .image-wrapper.bg-cover {
    min-height: 20rem;
  }
}
.bg-lines {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../images/photos/lines.png) no-repeat center center;
    background-size: cover;
    pointer-events: none;
  }
}
//----------------------------------------------------------//
//	BACKGROUND VIDEO
//----------------------------------------------------------//
.video-wrapper {
  position: relative;
  overflow: hidden;
  &.bg-overlay:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($gray-900, 0.4);
    z-index: 1;
  }
  &.bg-overlay.bg-overlay-gradient:after {
    opacity: 0.4;
    background: linear-gradient(120deg, #542461 50%, #332487 100%);
  }
  video {
    object-fit: cover;
    display: block;
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0;
    left: 0;
    background: $black;
    &:-webkit-media-controls {
      display: none !important;
    }
  }
  .video-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
}
//----------------------------------------------------------//
//	PATTERN WRAPPER
//----------------------------------------------------------//
.pattern-wrapper {
  background-repeat: repeat;
  background-attachment: fixed;
}
//----------------------------------------------------------//
//	ANGLED WRAPPERS
//----------------------------------------------------------//
@each $color, $value in $colors {
  .wrapper.bg-#{$color}.angled:after,
  .wrapper.bg-#{$color}.angled:before {
    border-color: var(--#{$prefix}#{$color});
  }
}
@each $color, $value in $colors-soft {
  .wrapper.bg-soft-#{$color}.angled:after,
  .wrapper.bg-soft-#{$color}.angled:before {
    border-color: var(--#{$prefix}soft-#{$color});
  }
}
@each $color, $value in $colors-pale {
  .wrapper.bg-pale-#{$color}.angled:after,
  .wrapper.bg-pale-#{$color}.angled:before {
    border-color: var(--#{$prefix}pale-#{$color});
  }
}
.wrapper.angled {
  position: relative;
  border: 0;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    z-index: 0;
    border-width: 0;
    border-style: solid;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
  }
  &.lower-end:after {
    border-left-color: transparent !important;
    border-right-width: 100vw;
    border-bottom-width: 4rem;
    bottom: -4rem;
  }
  &.lower-start:after {
    border-right-color: transparent !important;
    border-left-width: 100vw;
    border-bottom-width: 4rem;
    bottom: -4rem;
  }
  &.upper-end:before {
    border-left-color: transparent !important;
    border-right-width: 100vw;
    border-top-width: 4rem;
    top: -4rem;
  }
  &.upper-start:before {
    border-right-color: transparent !important;
    border-left-width: 100vw;
    border-top-width: 4rem;
    top: -4rem;
  }
}
footer.bg-dark.angled {
  &.lower-end:after,
  &.upper-end:before {
    border-right-color: $gray-800 !important;
  }
  &.lower-start:after,
  &.upper-start:before {
    border-left-color: $gray-800 !important;
  }
}
//----------------------------------------------------------//
//	BOX LAYOUT
//----------------------------------------------------------//
@media (min-width: $box-layout-width) {
  .box-layout {
    position: relative;
    background: $box-layout-bg;
  }
  .box-layout .content-wrapper,
  .box-layout footer:not(.blockquote-footer) {
    width: 100%;
    max-width: $box-layout-width !important;
    margin: 0 auto;
    position: relative;
  }
}
//----------------------------------------------------------//
//	SECTIONS
//----------------------------------------------------------//
// header.bg-transparent {
//   &:not(.pb-8) {
//     & + section.wrapper:not(.section-header-slider):not(.section-breadcrumb) {
//       padding-top: 4rem !important;
//       & > .container[class*="py-"] {
//         padding-top: 0 !important;
//       }
//     }
//     &.mb-8 {
//       & + section.wrapper:not(.section-header-slider):not(.section-breadcrumb) {
//         padding-top: 6rem !important;
//       }
//       & + section.wrapper.account-wrapper {

//       }
//     }
//   }
//   &.pb-8 {
//     &
//       + section.wrapper:not(.section-header-slider):not(
//         .section-breadcrumb
//       ):not([class*="py-"]) {
//       padding-top: 0 !important;
//     }
//   }
// }

// header.bg-transparent {
//   & + section.wrapper.section-breadcrumb {
//     margin-top: 5rem !important;
//   }
// }

// header.normal {
//   & + .generic-header {
//     padding-top: 5rem !important;
//   }
// }

section.wrapper[class*="py-"]:not([class*="bg-"]) {
  & + section.wrapper[class*="py-"]:not([class*="bg-"]) {
    padding-top: 0 !important;
  }
}

// @Todo DEBUG
// section.wrapper[class*="py-"]:not([class*="bg-"]:not([class*="std"])) {
//   & + section.wrapper[class*="py-"][class*="std"]:not([class*="bg-"]) {
//     margin-top: -3rem !important;
//   }
// }

section.wrapper[class*="py-"][class*="std"] {
  & + section.wrapper[class*="py-"]:not([class*="bg-"]:not([class*="std"])) {
    margin-top: -2rem !important;
  }
}

.page-frame section.wrapper[class*="py-"] + section.wrapper[class*="py-"] {
  padding-top: 3.5rem !important;
}

.std {
  h1 {
    @extend .display-1;
    margin-bottom: 0.75rem !important;
  }
  h2 {
    @extend .display-2;
    margin-bottom: 0.75rem !important;
  }
  h3 {
    @extend .display-3;
    margin-bottom: 0.75rem !important;
  }
  h4 {
    @extend .display-4;
    margin-bottom: 0.75rem !important;
  }
  h5 {
    @extend .display-5;
    margin-bottom: 0.75rem !important;
  }
  &.text-invert {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $white;
    }
    color: $white;
    a {
      color: $white;
      text-decoration: underline;
    }
    blockquote {
      border-left-color: $white;
    }
  }
  .float-left {
    float: left;
    margin-right: 40px;
    margin-bottom: 15px;
  }
  .float-right {
    float: right;
    margin-left: 40px;
    margin-bottom: 15px;
  }
  .float-none {
    float: none;
  }
  p {
    clear: both;
  }
  iframe {
    width: 100%;
    min-height: 600px;
  }
  a:not(.btn) {
    font-weight: 500;
  }
}

@each $color, $value in $colors-soft {
  section.wrapper.bg-soft-#{$color} {
    & + section.wrapper.bg-soft-#{$color} {
      padding-top: 0 !important;
    }
    .box-regular .std-box {
      ul,
      ol {
        li {
          &:before {
            background: var(--bs-#{$color});
            color: var(--bs-soft-#{$color});
          }
        }
      }
      .accordion-wrapper .card-header button:before,
      .accordion-wrapper .card-header button:hover {
        color: var(--bs-#{$color});
      }
      .accordion-wrapper .card-header button {
        color: var(--bs-#{$color});
      }
      .accordion-wrapper .card-header button.collapsed {
        color: $navy;
        &:hover {
          color: var(--bs-#{$color});
        }
      }
    }
  }
}

.std-box {
  ol {
    padding-left: 20px;
    list-style: none;
    counter-reset: ol-counter;
    margin: 2rem 0;
    li {
      counter-increment: ol-counter;
      line-height: 1.5;
      margin-bottom: 20px;
      text-indent: 0;
      padding-left: 30px;
      position: relative;
      padding-top: 5px;
      b,
      strong {
        font-size: 0.95rem;
      }
      &:before {
        content: counter(ol-counter);
        border-radius: 100% !important;
        width: 2.2rem;
        height: 2.2rem;
        line-height: 2.2rem;
        background: var(--bs-primary);
        color: $white;
        display: block;
        text-align: center;
        font-size: 0.9rem !important;
        font-weight: 700;
        display: inline-block;
        text-indent: 0;
        position: absolute;
        top: 0;
        left: -30px;
      }
      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      display: flex;
      line-height: 1;
      margin-bottom: 10px;
      &:before {
        font-family: Unicons !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-spacing: normal;
        content: "\e9dd";
        border-radius: 100%;
        background: var(--bs-bullet-soft-primary);
        color: var(--bs-primary);
        width: 1rem;
        height: 1rem;
        font-size: 0.8rem;
        line-height: 1rem;
        letter-spacing: normal;
        text-align: center;
        display: table;
        margin-right: 8px;
      }
    }
  }
}
.box-regular .std-box {
  &.text-invert {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $white;
    }
    color: $white;
    ul,
    ol {
      li {
        &:before {
          background: $white;
          color: var(--bs-primary);
        }
      }
    }
    .accordion-wrapper .card-header button:before,
    .accordion-wrapper .card-header button:hover {
      color: var(--bs-primary);
    }
    .accordion-wrapper .card-header button {
      color: var(--bs-primary);
    }
    .accordion-wrapper .card-header button.collapsed {
      color: $white;
      &:hover {
        color: var(--bs-primary);
      }
    }
  }
}
.std-video {
  a.btn {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 3;
  }
}
.gallery-section {
  a {
    img {
      @include imageBg();
    }
  }
}
.project-cards-section {
  a:not(.btn) {
    &:after {
      line-height: 1;
      font-family: Unicons;
      content: "\e94c";
      padding-left: 0.05rem;
      font-size: 0.9rem;
      font-weight: 400;
      vertical-align: -3px;
      margin-right: -0.25rem;
      display: inline-block;
    }
    font-weight: 700;
    letter-spacing: -0.01rem;
    display: inline-block;
  }
  a {
    margin-bottom: 2rem;
  }
  .text-invert:not(.bg-white) {
    h1,
    h2,
    h3,
    h4,
    h5,
    .post-category {
      color: $white !important;
    }
    color: $white;
    a {
      color: $white;
    }
  }
}
.card-body {
  &.text-invert:not(.bg-white) {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: $white !important;
    }
    color: $white;
    a {
      color: $white;
    }
    blockquote.icon:before {
      color: rgba($white, 0.05);
    }
  }
}

section.wrapper {
  .text-invert:not(.bg-white) {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: $white !important;
    }
    color: $white;
    a:not(.btn) {
      color: $white;
      &:hover {
        color: $white !important;
      }
    }
    blockquote.icon:before {
      color: rgba($white, 0.05);
    }
  }
}
.rotating-section {
  .container {
    z-index: 2;
  }
}
.slider-image-section {
  figure {
    height: 100%;
  }
  img {
    @include imageBg();
    height: 100% !important;
    max-height: 500px !important;
  }
}

body.disclaimer {
  // .modal-popup {
  //   backdrop-filter: blur(8px);
  // }
  .modal-backdrop {
    --bs-backdrop-bg: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(4px);
  }
}

.badge-xl {
  font-size: 14px;
  padding: 0.45rem 0.75rem;
  font-weight: 600;
}

.project-classic-image {
  img {
    @include imageBg();
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
  }
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }
}

.projets-item-details {
  &:nth-child(odd) {
    .project-text {
      margin-left: auto !important;
      @media (max-width: 991.98px) {
        order: inherit !important;
        margin-left: 0 !important;
      }
    }
  }
  &:nth-child(even) {
    .project-image {
      order: 2;
      margin-left: auto !important;
      @media (max-width: 991.98px) {
        order: inherit !important;
        margin-left: 0 !important;
      }
    }
  }
}

body.stores-map-open {
  @media (max-width: 767px) {
    overflow: hidden;
    .stores-container {
      #stores_list {
        .map-view {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.stores-container {
  & > .container {
    @media (max-width: 991.98px) {
      max-width: 100%;
    }
    & > .card {
      @media (max-width: 767px) {
        position: inherit;
      }
    }
  }
  #stores_list {
    .stores-wrapper {
      height: 710px;
      @media (max-width: 767px) {
        height: auto;
        flex-direction: column;
      }
      &.empty-result {
        height: auto;
        width: 100%;
        .stores-column {
          width: 100%;
          margin: 0 auto;
          padding: 30px 25px 30px 25px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
    .stores-column {
      width: 100%;
      padding: 30px 25px 50px 25px;
      @media (max-width: 991.98px) {
        padding: 30px 10px 50px 10px;
      }
      &.cols {
        padding: 30px 10px 50px 25px;
        width: 45%;
        @media (max-width: 991.98px) {
          padding: 30px 10px 50px 10px;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &.empty-result {
        width: 100%;
      }
      .items {
        height: calc(100% - 100px);
        padding: 0 15px 0;
        @media (max-width: 991.98px) {
          padding: 0 15px 0 0;
        }
        @media (max-width: 767px) {
          height: auto;
        }
      }
    }
    .map-view {
      height: 100%;
      width: 55%;
      overflow: hidden;
      border-radius: var(--bs-card-border-radius);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @media (max-width: 767px) {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 10001;
        border-radius: 0;
        background: $white;
        padding: 3rem 0.7rem 0.7rem 0.7rem;
        transition: opacity 0.25s ease;
      }
      #stores_map_close_btn {
        position: absolute;
        top: 0.6rem;
        right: 0.7rem;
      }
      #stores_map {
        height: calc(100% + 20px);
        @media (max-width: 767px) {
          height: 100%;
          border-radius: var(--bs-card-border-radius);
          overflow: hidden;
        }
        .item {
          max-width: 360px;
          border: none;
          padding: 8px 15px;
          border-radius: 0;
        }
        h4 {
          font-size: 18px;
          font-weight: 600;

          margin-bottom: 5px;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;

          line-height: 1.4;
          &.location {
            display: none;
          }
          &.address,
          &.phone,
          &.website {
            display: flex;
            align-items: baseline;
            i,
            span {
              display: none;
            }
          }
          &.phone {
            margin-top: 12px;
          }
          &.website {
            margin-top: 12px;
          }
          &.phone {
            & + .website {
              margin-top: 0;
            }
          }
          &.dir {
            margin-top: 12px;
          }
          &.website {
            & + .dir {
              margin-top: 0;
            }
          }
          &.phone {
            & + .dir {
              margin-top: 0;
            }
          }
        }
        a {
          display: inline-block;
          &:before {
            top: calc(100% + -2px) !important;
          }
        }
      }
    }
    &.wsearch {
      .search-box {
        .search-input {
          #stores_search_close {
            display: block;
          }
        }
      }
      .item {
        &.nmatch {
          display: none;
        }
        &:not(.nmatch) {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .results-list {
    .search-box {
      width: calc(100% - 15px);
      padding: 0 15px 20px 15px;
      border-bottom: solid 1px rgba(#cacaca, 0.6);
      margin-bottom: 15px;
      h3 {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
        color: $black;
      }
      .search-input {
        position: relative;
        i {
          line-height: 1;
        }
        label {
          position: absolute;
          left: 7px;
          top: 1px;
          z-index: 10;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
        }
        #stores_search_close {
          position: absolute;
          top: 6px;
          right: 10px;
          display: none;
        }
        input {
          border-radius: 5px;
          width: 100%;
          height: 34px;
          font-size: 14px;
          border: solid 1px rgba(#cacaca, 0.6);
          background: #f6f6f6;
          padding: 0 12px 0 27px;
        }
      }
    }
    .simplebar-scrollbar {
      border-radius: 5px;
      cursor: grabbing;
      &:hover {
        background: rgba($black, 0.8);
      }
    }
    .simplebar-track {
      background: darken($gray, 10%);
      opacity: 0;
      transition: opacity 0.2s 0.5s linear;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        opacity: 1;
        transition: opacity 0s 0s linear;
      }
    }
    .simplebar-mouse-entered,
    .simplebar-scrolling {
      .simplebar-track {
        opacity: 1;
        transition: opacity 0s 0s linear;
      }
    }
    #stores_list .items {
      .item {
        border: none;
        border-bottom: solid 1px rgba(#cacaca, 0.6);
        margin-bottom: 15px;
        padding: 8px 15px;
        border-radius: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h4 {
        font-size: 18px;
        font-weight: 600;
        color: $primary;
        margin-bottom: 8px;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        &:hover {
          color: darken($primary, 10%);
        }
      }
      p {
        font-size: 15px;
        margin-bottom: 10px;
        color: #3e4555;
        line-height: 1.4;
        font-weight: 400;
        &.location {
          margin-bottom: 22px;
          font-weight: 600;
          text-transform: uppercase;
        }
        a {
        }
        &.address,
        &.phone,
        &.website,
        &.dir {
          display: flex;
          align-items: baseline;
          i,
          span {
            margin-top: 2px;
            min-width: 25px;
            display: block;
          }
        }
      }
      a {
        display: inline-block;
      }
    }
  }

  #result_msg {
    font-size: 14px;
    padding: 0 15px;
  }
}

.notfound h1 {
  font-size: 238px;
  font-weight: 700;
  margin: 0;
  color: rgba($navy, 0.1);
  text-transform: uppercase;
  letter-spacing: 7px;
}

.contact-image.image-full {
  height: 100%;
  min-height: 15rem;
  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  @media (max-width: 1199px) {
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
  }
  &:not(.boxed) {
    img {
      object-fit: contain;
    }
  }
  img {
    @include imageBg();
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
  }
}

.feature-cards-wrapper {
  i.uil {
    font-size: 55px;
    line-height: 1;
  }
  &.with-img {
    height: 100%;
    overflow: hidden;
  }
  .img-full {
    position: relative;
    overflow: hidden;
    img {
      @include imageBg();
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.feature-cards-wrapper-img {
  position: relative;
  &:after {
    width: 100%;
    display: block;
    content: "";
    padding-bottom: 100%;
    @media (max-width: 1199px) {
      padding-bottom: 70%;
    }
  }
  img {
    @include imageBg();
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.avatar-image {
  position: relative;
  overflow: hidden;
  &:after {
    width: 100%;
    display: block;
    content: "";
    padding-bottom: 100%;
  }
  img {
    @include imageBg();
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
.testimonial-negative-image {
  position: relative;
  figure {
    position: absolute;
    top: 50%;
    right: 0;
    width: 45%;
    height: auto;
    transform: translateY(-50%);
    z-index: 2;
    &.reverse {
      right: auto;
      left: 0;
    }
  }
}

.testimonial-cards-align {
  .item {
    /* Style for elements 1, 5, 9, 13, ... */
    &:nth-child(4n + 1) {
      align-self: flex-end !important;
      @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 41.66666667%;
      }
    }

    /* Style for elements 2, 6, 10, 14, ... */
    &:nth-child(4n + 2) {
      align-self: flex-end !important;
    }

    /* Style for elements 3, 7, 11, 15, ... */
    &:nth-child(4n + 3) {
      @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 41.66666667%;
        margin-left: 8.33333333%;
      }
    }

    /* Style for elements 4, 8, 12, 16, ... */
    &:nth-child(4n + 4) {
      align-self: flex-start !important;
    }
  }
}
.card-team-square {
  figure {
    position: relative;
    &:after {
      width: 100%;
      display: block;
      content: "";
      padding-bottom: 100%;
    }
    img {
      @include imageBg();
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.team-shape-square {
  .shape {
    bottom: -0.75rem;
    right: -0.75rem;
    width: 98%;
    height: 98%;
    z-index: 0;
  }
}
.privacy-box-cookies {
  background: $white;
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 350px;
  z-index: 99992;
  padding: 30px;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.5s;
  &.accepted {
    transform: translateX(120%);
    opacity: 0;
    ul li {
      span {
        color: $success;
        &:before {
          content: "\e9db";
        }
      }
      &:before {
        content: "";
        background: $success;
        position: absolute;
        left: -12px;
        top: 0;
        height: calc(100% + 4px);
        width: 5px;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 18px 0;
    font-weight: 500;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 12px;
    position: relative;
    &:before {
      content: "";
      background: #d3d3d3;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 5px;
    }
    li {
      margin: 0;
      padding: 0;
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 4px;
      span {
        margin-right: 8px;
        font-size: 20px;
        color: #d3d3d3;
        line-height: 1;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      &:before {
        content: "";
        background: #d3d3d3;
        position: absolute;
        left: -12px;
        top: 0;
        height: calc(100% + 4px);
        width: 5px;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        span {
          color: $success;
        }
        &:before {
          content: "";
          background: $success;
          position: absolute;
          left: -12px;
          top: 0;
          height: calc(100% + 4px);
          width: 5px;
        }
      }
    }
  }
  a {
    margin-right: 8px;
  }
}

.section-img-col-text {
  .inner {
    p {
      margin-bottom: 0.5rem !important;
    }
    a:not(.btn) {
      &:after {
        line-height: 1;
        font-family: Unicons;
        content: "\e94c";
        padding-left: 0.05rem;
        font-size: 0.9rem;
        font-weight: 400;
        vertical-align: -3px;
        margin-right: -0.25rem;
        display: inline-block;
      }
      font-weight: 600;
      letter-spacing: -0.01rem;
      display: inline-block;
      color: currentColor;
    }
  }
}
.focus-row-section {
  a {
    position: relative;
    &:after {
      width: 100%;
      display: block;
      content: "";
      padding-bottom: 70%;
    }
    img {
      @include imageBg();
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.swiper.g-review {
  .item-inner {
    padding-top: 40px;
  }
}
.g-review-card {
  .card-body {
    padding-bottom: 20px;
    padding-top: calc(var(--bs-card-spacer-y) + 10px);
    a {
      margin-top: 8px;
      color: $primary;
      font-size: 13px;
      font-weight: 600;
    }
  }
  .blockquote-details {
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.g-review-wrapper {
  .popover {
    font-size: 14px;
  }
  .popover-body {
    padding: 1rem 1.25rem 1rem;
    line-height: 1.4;
  }
}

.col-main-wrapper {
  .container,
  .container-fluid {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }
}

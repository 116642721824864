//----------------------------------------------------------//
//	UTILITIES
//----------------------------------------------------------//
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(map-get(map-get($utilities, "width"), "values"), $spacers),
        )
      ),
    "height":
      map-merge(
        map-get($utilities, "height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "height"), "values"),
              $spacers
            ),
        )
      ),
    "opacity": (
      property: opacity,
      values: (
        0: 0,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        90: 0.9,
        100: 1,
      ),
    ),
    "font-size": null,
    "line-height": (
      property: line-height,
      class: lh,
      values: (
        1: 1,
        xxs: $line-height-xxs,
        xs: $line-height-xs,
        sm: $line-height-sm,
        base: $line-height-base,
        lg: $line-height-lg,
      ),
    ),
    "min-viewport-height": (
      property: min-height,
      class: min-vh,
      values: (
        25: 25vh,
        30: 30vh,
        50: 50vh,
        60: 60vh,
        70: 70vh,
        75: 75vh,
        80: 80vh,
        100: 100vh,
      ),
    ),
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              $text-colors,
              (
                "white": $white,
                "body": $body-color,
                "muted": $text-muted,
                "inverse": $inverse,
                "dark": $main-dark,
                "black-50": rgba($black, 0.5),
                "white-50": rgba($white, 0.5),
                "reset": inherit,
              )
            ),
        )
      ),
    "shadow": (
      property: box-shadow,
      class: shadow,
      values: (
        null: $box-shadow,
        sm: $box-shadow-sm,
        lg: $box-shadow-lg,
        xl: $box-shadow-xl,
        none: none,
      ),
    ),
    "rounded": (
      property: border-radius,
      class: rounded,
      values: (
        null: $border-radius,
        0: 0,
        1: $border-radius-sm,
        2: $border-radius,
        3: $border-radius-lg,
        4: $border-radius-xl,
        circle: 50%,
        pill: $border-radius-pill,
      ),
    ),
  )
);
div > .text-muted {
  opacity: 0.7;
  color: $navy !important;
}

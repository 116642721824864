#add_to_message {
  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    color: $danger;
  }
}
#addtocart_id_input {
  height: 1px;
  overflow: hidden;
  position: absolute;
  left: -9999px;
}
.out-of-stock {
  .label {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    color: $danger;
  }
  .message {
    font-weight: 600;
    font-size: 14px;
  }
}
#attributes_swap .variation-picker legend {
  color: $danger;
}
//----------------------------------------------------------//
//	SHOP
//----------------------------------------------------------//
.shop figure {
  overflow: hidden;
  transform: translate3d(0px, 0px, 0px);
}

.price {
  margin: 0;
  del {
    color: $ash;
  }
  ins {
    text-decoration: none;
    color: $red;
  }
}
.shopping-cart .shopping-cart-item {
  figure {
    a {
      position: relative;
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        @include imageBg();
        height: 100% !important;
      }
    }
  }
  ul {
    padding: 0;
    margin-bottom: 0.4rem;
    li {
      list-style-type: none;
      line-height: 1.4;
      strong {
        font-weight: 600;
      }
    }
  }
  .price {
    margin-bottom: 0.4rem;
  }
}
table.shopping-cart {
  td {
    vertical-align: middle;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}
#cart_loader_btn {
  display: none;
}
#cart_items_container {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.4);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
}
#cart_items_container.loading {
  &:before {
    opacity: 1;
    z-index: 20;
  }
  #cart_loader_btn {
    display: block;
  }
  #cart_submit_btn {
    display: none;
  }
}
.checkout-items-totals {
  small {
    display: block;
    font-weight: 600;
    font-size: 12px;
    text-transform: initial !important;
  }
}
.empty-shipping-method {
  .card {
    background-color: $gray-300;
    p {
      line-height: 1.2;
    }
  }
}

#submit_checkout_box_btn.disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.shipping-method-select,
.address-type-select,
#customer_account_method,
#customer_shipping_address_select,
#customer_billing_address_select {
  label {
    width: 100%;
    font-size: 1rem;
    & + label {
      margin-top: 0.4rem;
    }
  }
  a {
    & + label {
      margin-top: 0.4rem;
    }
  }

  .card-input-element + .card {
    color: $primary;
    box-shadow: none;
    border: 1px solid $primary;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    background: transparent;
    position: relative;
  }

  .card-input-element + .card:hover {
    cursor: pointer;
  }

  .card-input-element:checked + .card {
    transition: border 0.3s ease;
    background: $primary-soft;
  }

  .card-input-element + .card::after {
    content: "\e9df";
    color: $primary;
    font-family: "Unicons";
    font-size: 24px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  .card-input-element:checked + .card::after {
    content: "\e9db";
  }
}
.address-type-select,
#customer_shipping_address_select,
#customer_billing_address_select {
  label {
    .name {
      line-height: 1.4;
      padding-right: 35px;
    }
    .desc {
      line-height: 1.4;
      padding-right: 35px;
      font-size: 13px !important;
    }
  }
}
#customer_account_method {
  .name {
    line-height: 1.4;
  }
  .desc {
    line-height: 1.4;
  }
}
#checkout_totals_container {
  .card-footer {
    background: $gray-300;
    color: $dark;
  }
}
#submit_checkout_box .error {
  font-size: 12px;
  color: $danger;
  margin-top: 10px;
  font-weight: 500;
  line-height: 1.4;
}
#checkout_payment_form {
  &.loading {
    .row > [class*="col-"] > .card {
      position: relative;
      overflow: hidden;
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -1.25rem;
        margin-left: -1.25rem;
        background: 0 0;
        border: 4px solid var(--bs-primary);
        box-shadow: none;
        border-radius: 50%;
        animation: loader-scale 1s ease-out infinite;
        z-index: 101;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 99;
        background: $white;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
      }
    }
  }
}

.rangeslider,
.rangeslider__fill {
  display: block;
  border-radius: 10px;
}

.rangeslider {
  position: relative;
}
.rangeslider:after {
  top: 50%;
  left: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 5px;
  margin-top: -2.5px;
  border-radius: 5px;
  position: absolute;
  background: #dee2e6;
}

.rangeslider--horizontal {
  width: 100%;
  height: 28px;
}

.rangeslider--vertical {
  width: 5px;
  min-height: 150px;
  max-height: 100%;
}
.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeslider__fill {
  position: absolute;
  background: var(--bs-primary);
}
.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}
.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%;
}

.rangeslider__handle {
  top: 50%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: -9px;
  background: white;
  position: absolute;
  background: var(--bs-primary);
  border-radius: 50%;
  display: inline-block;
}
.rangeslider__handle:active {
  background: var(--bs-primary);
}

.rangeslider__fill,
.rangeslider__handle {
  z-index: 1;
}
.rangeslider--horizontal .rangeslider__fill {
  top: 50%;
  height: 5px;
  margin-top: -2.5px;
}

.price-slider {
  .range-value {
    margin-top: 8px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    span {
      border: solid 1px rgba(#cacaca, 0.6);
      border-radius: $border-radius;
      padding: 6px 14px 6px 14px;
      font-weight: 600;
      font-size: 14px;
      min-width: 100px;
      &.max {
        text-align: right;
      }
    }
  }
}

.catalog-sidebar {
  .filter-active {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px rgba(#cacaca, 0.6);
    .filter-active-items {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      li {
        list-style: none;
        margin-bottom: 5px;
        margin-right: 5px;
        a {
          border-radius: $border-radius;
          display: flex;
          align-items: center;
          font-size: 14px;
          span.pill {
            border: solid 1px rgba(#cacaca, 0.6);
            border-bottom-left-radius: $border-radius;
            border-top-left-radius: $border-radius;
            display: block;
            padding: 4px 8px;
            text-align: center;
            line-height: 1.2;
            height: 100%;
            span {
              font-weight: 700;
              font-size: 11px;
            }
          }
          span.value {
            border: solid 1px rgba(#cacaca, 0.6);
            border-bottom-right-radius: $border-radius;
            border-left: none;
            border-top-right-radius: $border-radius;
            padding: 4px 8px;
            line-height: 1.2;
          }
          &:hover {
            background: rgba(#cacaca, 0.3);
            color: initial !important;
          }
        }
      }
    }
    .remove-filters {
      font-size: 13px;
      text-decoration: underline;
      font-weight: 500;
      &:hover {
        color: $danger !important;
      }
    }
  }
  .widget-title {
    font-size: 15px;
    margin-bottom: 1.25rem;
  }
  .filter-item {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px rgba(#cacaca, 0.6);
    &.categories-filter {
      li {
        margin-top: 0.3rem;
        ul {
          padding-left: 0.5rem !important;
        }
      }
      li a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 13px;
          opacity: 0.7;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0.5rem 0 0 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .filter-options {
      &.type-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        li {
          width: max-content !important;
          min-width: calc(100% / 2 - 10px) !important;
          overflow-y: hidden;
          a {
            margin: 0;
            border: solid 1px rgba(#cacaca, 0.6);
            border-radius: $border-radius;
            padding: 6px 14px 6px 14px;
            &.active,
            &:hover {
              background: rgba(#cacaca, 0.3);
              color: initial !important;
            }
          }
        }
      }
      &.type-color {
        padding-bottom: 5px;
        span.color {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          position: relative;
          & + .filter-label {
            width: calc(100% - 30px);
          }
          i {
            position: absolute;
            font-size: 20px;
            top: 0;
            left: 0;
            line-height: 1;
          }
        }
        .label {
        }
        .count {
          font-size: 13px;
          opacity: 0.7;
        }
      }
    }
  }
}
.catalog-top-bar-toolbar {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 0.625rem;
  margin-bottom: 0.9375rem;
  border-bottom: solid 1px rgba(#cacaca, 0.6);
  .product-toolbar-count {
    font-size: 14px;
  }
  .catalog-length {
    font-size: 14px;
    margin-left: 14px;
    padding-left: 10px;
  }
  .catalog-sort {
    font-size: 14px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 50%;
      width: 1px;
      background: rgba(#cacaca, 0.6);
    }
  }
  select {
    border: none;
    box-shadow: none;
    font-size: 14px;
    color: inherit;
    padding: 0 20px 0 0;
    background-position: right 0 center;
    font-weight: 600;
  }
}
.catalog-wrapper {
  padding-bottom: 2.5rem;
  .catalog-items {
    border-left: solid 1px rgba(#cacaca, 0.6);
  }
}
.catalog-product-list {
  .shop {
    .product-col {
      padding-left: calc((var(--bs-gutter-x) / 2) * 0.8);
      padding-right: calc((var(--bs-gutter-x) / 2) * 0.8);
      margin-bottom: calc(var(--bs-gutter-x) * 0.8);
    }
    .product-item {
      position: relative;
      transition: all 0.25s ease;
      box-shadow: 0 0 48px rgba(0, 0, 0, 0.06);
      height: 100%;
      border-radius: $border-radius;
      overflow: hidden;
      &:hover {
        box-shadow: 0 0 58px rgba(0, 0, 0, 0.12);
        .post-header {
          .post-title {
            color: $primary;
          }
        }
      }
      .thumb-tags {
        position: absolute;
        z-index: 20;
        padding: 10px 15px;
        display: flex;
        span {
          margin-right: 8px;
        }
      }
      .stock-status {
        font-size: 13px;
        font-weight: 600;
      }
      .colors-list {
        display: flex;
        margin-top: 0.5rem;
        .pill {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          position: relative;
          margin-right: 8px;
        }
      }
      .post-header {
        padding: 18px 22px;
      }
      .product-link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-indent: -9999px;
      }
      .desc {
        line-height: 1.4;
      }
      .price {
        font-size: 18px !important;
        margin-bottom: 0 !important;
        del {
          font-size: 15px;
        }
        .from-price {
          font-size: 14px;
        }
        span.amount {
          font-weight: 600;
        }
      }
    }
    figure img {
      @include imageBg();
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
    }
    &.display-square {
      figure {
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
    &.display-vertical {
      figure {
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-bottom: 125%;
        }
      }
    }
    &.display-horizontal {
      figure {
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-bottom: 75%;
        }
      }
    }
  }
}
.products-cross-section {
  .catalog-product-list {
    .shop {
      .product-col {
        padding: 0;
        margin: 0;
      }
      .product-item {
        box-shadow: none;
      }
    }
  }
}
#checkout_message_box {
  p {
    line-height: 1.25;
  }
}
.navbar .continue-shopping-btn {
  position: absolute;
  font-size: 14px;
  right: calc(var(--bs-gutter-x) * 0.5);
  top: 50%;
  transform: translateY(-50%);
}
.dashboard-menu {
  .nav-item {
    a {
      border: solid 1px rgba(#cacaca, 0.6);
      &.btn-soft-primary {
        border: solid 1px transparent;
      }
      &.btn-outline-danger {
        border: solid 1px $danger;
        &:hover {
          color: $white !important;
        }
      }
      @media (max-width: 991.98px) {
        &:not(.btn-soft-primary) {
          background: $white;
          border-color: $white;
        }
        &:not(.btn-soft-primary).btn-outline-danger {
          color: $danger !important;
        }
        i {
          display: none;
        }
      }
    }
  }
}
#finfos_new_pwd,
#customer_account_pwd {
  &:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

#mask_password {
  visibility: hidden;
  opacity: 0;
  position: relative;
  transition: opacity 0s;
  @media (max-width: 1199.98px) {
    opacity: 1;
    visibility: visible;
    height: 0;
    overflow: hidden;
    &.focused {
      height: auto;
    }
  }
  &.focused {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s ease;
  }
}
#pr-box {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + calc($form-floating-height / 1.5));
  margin-top: -2px;
  border: 1px solid;
  border-color: $input-focus-border-color;
  background: #f1f1f1;
  user-select: none;
  @include border-radius($input-border-radius, 0);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  @media (max-width: 1199.98px) {
    position: relative;
    margin-top: -2px;
    top: auto;
  }
  ul {
    padding: $form-floating-padding-x;
    margin: 0;
    li {
      list-style: none;
      padding: 0;
      font-size: 12px;
      display: flex;
      align-items: center;
      span {
        width: 15px;
        height: 15px;
        display: block;
        float: left;
        border-radius: 100%;
        margin-right: 15px;
        border: 2px solid $success;
        &.pr-ok {
          background-color: $success;
        }
      }
    }
  }
}
.mask-password {
  border: 1px solid;
  border-color: $input-focus-border-color;
  border-top: 0;
  width: 100%;
  top: calc(100% - 1px);
  background: #f3f6f9;
  padding: $form-floating-padding-y $form-floating-padding-x;
  position: absolute;
  height: calc($form-floating-height / 1.5);
  min-height: calc($form-floating-height / 1.5);
  overflow: hidden;
  transition: all 0.2s ease;
  user-select: none;
  @media (max-width: 1199.98px) {
    position: relative;
    margin-top: -1px;
    top: auto;
  }
  span {
    color: $navy;
    font-size: 14px;
    position: absolute;
    left: $form-floating-padding-x;
    top: 50%;
    transform: translateY(-50%);
  }
  i {
    position: absolute;
    right: $form-floating-padding-x;
    top: 50%;
    transform: translateY(-50%);
  }
}
.account-page-wrapper {
  .card-header {
    .back-link {
      display: flex;
      align-items: center;
      i {
        margin-right: 2px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .card-body {
    h4 {
      @media (max-width: 399px) {
        font-size: 13px !important;
      }
    }
  }
  .address-box {
    border: solid 1px rgba(#cacaca, 0.6);
    margin-bottom: calc((var(--bs-gutter-x) * 0.5) * 2);
    padding: 15px;
    address {
      margin-bottom: 0.5rem;
      .badge {
        margin-bottom: 15px;
      }
      h6 {
        font-size: 16px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .btn-address-link {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      padding: 0;
      text-decoration: underline;
      font-size: 14px;
      margin-right: 10px;
      &.remove {
        color: $danger;
      }
    }
  }
  .card-header {
    min-height: 3.599rem;
    .btn-expand {
      max-height: 1.8rem;
      font-size: 14px;
      padding-top: 0.28rem;
      padding-bottom: 0.28rem;
      span {
        padding-left: 2.5rem;
      }
      i {
        width: 1.8rem;
        height: 1.8rem;
        font-size: 1rem;
      }
      &:before {
        width: 1.8rem;
        height: 1.8rem;
      }
      &:hover {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

.customer-coupons-wrapper {
  .item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px rgba(#cacaca, 0.6);
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .icon {
    line-height: 1;
  }
  .text {
    h3 {
      font-weight: 600;
      margin-top: 10px;
      font-size: 16px;
      margin-bottom: 0;
    }
    h4 {
      margin-top: 5px;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .actions {
    .amount {
      white-space: nowrap;
    }
    .coupon-code {
      border: 1px dashed;
      border-right: 0;
      padding: 5px 10px;
      display: flex;
      h5 {
        margin: 0;
        align-self: center;
      }
      &:not(.copy) {
        background: $gray;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
      &.copy {
        cursor: pointer;
      }
    }
    .btn-code {
      a {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        min-width: 110px;
        &:hover {
          transform: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
.customer-orders-table {
  td {
    font-size: 14px;
    strong {
      font-weight: 600;
    }
    a:not(.btn) {
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: $dark;
        bottom: 0;
        left: 0;
      }
    }
    a.btn {
      padding: 0px 20px;
    }
  }
}
.order-error-state {
  p {
    margin-bottom: 0;
  }
}
.customer-order-details {
  .inner {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2,
  h4 {
    font-size: 20px;
    font-weight: 600;
  }
  .track-order-link {
    border: 1px dashed;
    border-radius: $border-radius;
    // display: inline-block;
    display: block;
    background: rgba(#cacaca, 0.1);
    padding: 20px;
    .svg-bg {
      margin-right: 20px;
      width: 2rem;
      height: 2rem;
      .icon-svg {
        width: 1rem;
        height: 1rem;
      }
    }
    h4 {
      font-size: 15px;
      margin-bottom: 0;
      font-weight: 600;
    }
    h5,
    a {
      font-size: 15px;
      font-weight: 400;
    }
  }
  address {
    font-size: 15px;
    strong {
      font-size: 16px;
    }
  }
  table {
    th,
    td {
      padding: 0.5rem 0.5rem;
    }
    .text-end {
      white-space: nowrap;
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  .totals {
    span {
      display: block;
      margin-right: 0.5rem;
      line-height: 1.2;
    }
    small {
      display: block;
    }
  }
}
#customize_preview {
  font-size: 15px;
  font-weight: 600;
  a {
    margin-right: 8px;
    i {
      font-size: 20px;
    }
  }
}
#customize_swap {
  .panel-upload {
    display: block;
    width: 100%;
    position: relative;
  }

  .customizebtn-area {
    z-index: 20;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .panel-upload-dropzone {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .panel-upload-dropzone {
    border: 2px dashed rgba(var(--bs-dark-rgb), 0.2);
    border-radius: $border-radius;
    padding: 30px;
    &.invalid-field {
      border: 2px dashed $danger;
    }
  }

  .panel-upload-help-message {
    line-height: 13px;
    font-size: 11px;
    color: #777;
    padding-bottom: 5px;
  }

  .panel-upload-message {
    line-height: 13px;
    font-size: 11px;
    color: #777;
    padding-top: 5px;
  }

  .panel-upload-message.error {
    color: #cd3646;
  }

  .btn-uploader.disabled {
    display: none;
  }

  .customize-progress {
    border-radius: 6px;
    background: rgba(#cacaca, 0.6);
    overflow: hidden;
    width: 100%;
    height: 15px;
    .customize-progressbar {
      background: $primary;
      width: 100%;
      height: 15px;
    }
  }
}

.tooltip-c-remove {
  font-size: 13px !important;
  font-weight: 600 !important;
  --bs-tooltip-bg: var(--bs-danger);
  .tooltip-inner {
    padding: 4px 12px !important;
  }
}

.product-image-gallery {
  .swiper-main {
    .swiper-slide figure {
      &:after {
        content: "";
        display: block;
        padding-bottom: 75%;
      }
      img {
        @include imageBg();
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .swiper-thumbs {
    .swiper-slide {
      &:after {
        content: "";
        display: block;
        padding-bottom: 75%;
      }
      img {
        @include imageBg();
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
.product-main {
  .product-header {
    h1 {
      &:after {
        content: "";
        width: 100px;
        height: 0;
        border-top: 1px solid rgba(#cacaca, 0.6);
        display: block;
        margin: 8px 0 20px 0;
      }
    }
  }
  .product-description {
    font-size: 14px;
    p {
      margin-bottom: 5px;
    }
    a {
      font-weight: 500;
      @include hoverBtn(true);
    }
  }
}
.product-informations {
  .nav.nav-tabs {
    border-bottom: 1px solid rgba(#cacaca, 0.6);
    .nav-link {
      border-color: transparent;
      margin-right: 1.5rem;
      font-size: 15px;
      font-weight: 600;
      &.active,
      &:hover {
        border-color: var(--bs-primary);
      }
    }
  }
  .tab-content {
    padding-right: 15px;
  }
  table td {
    padding: 0.4rem 0.4rem;
    &.label {
      width: 1px;
      white-space: nowrap;
    }
  }
  .datas {
    .list {
      margin-bottom: 10px;
      dl {
        display: flex;
        margin-bottom: 0;
        align-items: center;
      }
      dt {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
      }
      dd {
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        margin-left: 12px;
        margin-bottom: 0;
      }
    }
  }
}
.products-cross-section {
  h3 {
    width: 100%;
    border-bottom: 1px solid rgba(#cacaca, 0.6);
    padding-bottom: 12px;
    margin-bottom: 0;
  }
  .swiper-pagination {
    bottom: auto !important;
    top: -3rem;
    right: 0;
    text-align: end;
  }
}

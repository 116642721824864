//----------------------------------------------------------//
//	BACKGROUND COLORS
//----------------------------------------------------------//

.project-cards-section {
  @each $color, $value in $colors-soft {
    .bg-soft-#{$color} {
      a:not(.btn) {
       color: var(--#{$prefix}#{$color}) !important;
      }
    }
  }
}

@each $color, $value in $colors {
  section.wrapper .bg-#{$color} {
    @if $color == "gray" {
      .btn.btn-primary {
        --bs-btn-bg: var(--#{$prefix}primary);
        --bs-btn-border-color: var(--#{$prefix}primary);
        --bs-btn-hover-bg: var(--#{$prefix}primary);
        --bs-btn-hover-border-color: var(--#{$prefix}primary);
        --bs-btn-active-bg: var(--#{$prefix}primary);
        --bs-btn-active-border-color: var(--#{$prefix}primary);
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        &.section-title {
          b,
          strong {
            color: var(--#{$prefix}primary);
          }
        }
      }
      u:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='#{url-color($primary)}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E") !important; 
      }
      strike {
        &:before {
          border-color: $primary !important;
        }
        &:after {
          border-color: $primary !important;
        }
      }
    } @else if $color == "dark" {
        .btn.btn-primary {
          --bs-btn-bg: transparent;
          --bs-btn-border-color: var(--bs-white);
          --bs-btn-hover-bg: transparent;
          --bs-btn-hover-border-color: var(--bs-white);
          --bs-btn-active-bg: transparent
          --bs-btn-active-border-color: var(--bs-white);
          --bs-btn-color: var(--bs-white);
          --bs-btn-hover-color: var(--bs-white);
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
          &.section-title {
            b,
            strong {
              color: $gray-400;
            }
          }
        }
        u:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='#{url-color($white)}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E") !important;
        }
        strike {
          &:before {
            border-color: $gray-400 !important;
          }
          &:after {
            border-color: $gray-400 !important;
          }
        }
      } @else if $color == "light" or $color == "white" {
        // .btn.btn-primary {
        //   --bs-btn-bg: transparent;
        //   --bs-btn-border-color: var(--bs-white);
        //   --bs-btn-hover-bg: transparent;
        //   --bs-btn-hover-border-color: var(--bs-white);
        //   --bs-btn-active-bg: transparent
        //   --bs-btn-active-border-color: var(--bs-white);
        //   --bs-btn-color: var(--bs-white);
        //   --bs-btn-hover-color: var(--bs-white);
        // }
        h1,
        h2,
        h3,
        h4,
        h5 {
          &.section-title {
            b,
            strong {
              color: $primary;
            }
          }
        }
        u:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='#{url-color($primary)}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E") !important;
        }
        strike {
          &:before {
            border-color: $primary !important;
          }
          &:after {
            border-color: $primary !important;
          }
        }
      } @else {
      .btn.btn-primary {
        --bs-btn-bg: var(--#{$prefix}soft-#{$color});
        --bs-btn-border-color: var(--#{$prefix}soft-#{$color});
        --bs-btn-hover-bg: var(--#{$prefix}soft-#{$color});
        --bs-btn-hover-border-color: var(--#{$prefix}soft-#{$color});
        --bs-btn-active-bg: var(--#{$prefix}soft-#{$color});
        --bs-btn-active-border-color: var(--#{$prefix}soft-#{$color});
        --bs-btn-color: var(--#{$prefix}#{$color});
        --bs-btn-hover-color: var(--#{$prefix}#{$color});
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        &.section-title {
          b,
          strong {
            color: var(--#{$prefix}soft-#{$color});
          }
        }
      }
      u:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='#{url-color( map-get($colors-soft, $color))}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E") !important;
      }
      strike {
        &:before {
          border-color: var(--#{$prefix}soft-#{$color}) !important;
        }
        &:after {
          border-color: var(--#{$prefix}soft-#{$color}) !important;
        }
      }
    }
  }
}

@each $color, $value in $colors-soft {
  .bg-soft-#{$color} {
    background-color: var(--#{$prefix}soft-#{$color}) !important;
    .btn.btn-primary {
      --bs-btn-bg: var(--#{$prefix}#{$color});
      --bs-btn-border-color: var(--#{$prefix}#{$color});
      --bs-btn-hover-bg: var(--#{$prefix}#{$color});
      --bs-btn-hover-border-color: var(--#{$prefix}#{$color});
      --bs-btn-active-bg: var(--#{$prefix}#{$color});
      --bs-btn-active-border-color: var(--#{$prefix}#{$color});
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      &.section-title {
        b,
        strong {
          color: var(--#{$prefix}#{$color});
        }
      }
    }
    u:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='#{url-color( map-get($colors, $color))}' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E") !important;
    }
    strike {
      &:before {
        border-color: var(--#{$prefix}#{$color}) !important;
      }
      &:after {
        border-color: var(--#{$prefix}#{$color}) !important;
      }
    }
  }
}

@each $color, $value in $colors-pale {
  .bg-pale-#{$color} {
    background-color: var(--#{$prefix}pale-#{$color}) !important;
  }
}
@each $color, $value in $colors-soft {
  .bg-gradient-#{$color} {
    background: linear-gradient(
      180deg,
      var(--#{$prefix}soft-#{$color}),
      rgba(var(--#{$prefix}white-rgb), 0) 100%
    );
  }
  .bg-gradient-reverse-#{$color} {
    background: linear-gradient(
      0deg,
      var(--#{$prefix}soft-#{$color}),
      rgba(var(--#{$prefix}white-rgb), 0) 100%
    );
  }
}
.bg-gradient-blend {
  background: linear-gradient(160deg, #ffb7b7 9%, #727272 100%),
    radial-gradient(100% 100% at 30% 10%, #ffd1d1 0%, #260000 100%),
    linear-gradient(180deg, #00ffff 0%, #ffffff 100%),
    radial-gradient(100% 100% at 70% 0%, #ff0000 0%, #00ffe0 100%),
    linear-gradient(280deg, #dbff00 0%, #3300ff 100%);
  background-blend-mode: screen, overlay, color-burn, color-dodge, normal;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#383838',GradientType=0 ); /* IE6-9 */
}
.bg-white-700 {
  background: rgba(var(--#{$prefix}white-rgb), 0.7) !important;
}
.bg-white-900 {
  background: rgba(var(--#{$prefix}white-rgb), 0.9) !important;
}
//----------------------------------------------------------//
//	BACKGROUND HEIGHT
//----------------------------------------------------------//
.bg-height {
  height: auto;
  min-height: 32rem;
  display: flex;
  align-items: center;
  &.header-height-small.generic-header {
    min-height: auto !important;
  }
  &.header-height-small:not(.generic-header) {
    height: auto;
    min-height: 23rem;
  }
  &.header-height-large {
    height: auto;
    min-height: 80vh;
  }
  &.header-height-full {
    height: 100vh;
    min-height: auto;
    @media (max-height: 649px) {
      min-height: 32rem;
    }
  }
}

@media (max-width: 991.98px) {
  .bg-height {
    height: auto;
    min-height: 20rem;
    &.header-height-small.generic-header {
      min-height: auto !important;
    }
    &.header-height-small:not(.generic-header) {
      height: auto;
      min-height: 15rem;
    }
    &.header-height-large {
      height: auto;
      min-height: 80vh;
    }
    &.header-height-full {
      height: 100vh;
    }
  }
}
@media (max-width: 767px) {
  .bg-height {
    height: auto;
    min-height: 15rem;
    &.header-height-small.generic-header {
      min-height: 0 !important;
    }
    &.header-height-small:not(.generic-header) {
      height: auto;
      min-height: 15rem;
    }
    &.header-height-large {
      height: auto;
      min-height: 80vh;
    }
    &.header-height-full {
      height: 100vh;
    }
  }
}
.bg-boxed-frame {
  background-color: $bgBoxedColor;
}

//----------------------------------------------------------//
//	FORMS
//----------------------------------------------------------//
input,
textarea,
select {
  -webkit-appearance: none !important;
}
.form-floating {
  &.hidden {
    input {
      position: absolute;
      left: -9999px;
    }
  }
  &:not(.form-control:disabled)::before {
    background: none;
  }
  > label {
    color: $form-label-color;
    @include font-size($input-font-size);
  }
  &.input-group label {
    margin-left: 0.05rem !important;
  }
  > textarea.form-control {
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t + 0.3rem;
    }
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t + 0.3rem;
    }
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      color: $form-label-color;
      font-weight: 600;
      &::after {
        background-color: transparent;
      }
    }
  }
}
.search-form {
  position: relative;
  .form-control {
    margin: 0;
    padding-right: 2.25rem !important;
  }
  &:before {
    content: $icon-search;
    font-family: $font-family-unicons;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    @include font-size(0.9rem);
    color: $input-placeholder-color;
    z-index: 1;
  }
}
.password-toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.75rem;
  cursor: pointer;
  @include font-size(0.9rem);
  color: $input-placeholder-color;
}
.form-check-input {
  box-shadow: $box-shadow;
  margin-top: 0.2rem;
}
.form-select {
  background-clip: padding-box;
  padding: 0.4rem 2rem 0.4rem 1rem;
  line-height: 1.8;
  &.form-select-sm {
    width: auto;
    min-width: 2.5rem;
    padding: 0.2rem 0.5rem;
    background-position: right 0.4rem center;
  }
}
.mc-field-group {
  text-align: left !important;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 0;
}
.input-group > .btn {
  max-height: 2.6rem;
}
form.dark-fields .form-control {
  background: rgba(var(--#{$prefix}white-rgb), 0.03);
  border-color: rgba(var(--#{$prefix}white-rgb), 0.1);
  color: $gray-300;
  &:focus {
    border-color: rgba(var(--#{$prefix}primary-rgb), 0.5);
  }
}
form .form-control {
  border-color: rgba(var(--#{$prefix}dark-rgb), 0.2);
}
.picker {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  label {
    position: relative;
    inline-size: 2rem;
    block-size: 2rem;
    margin-right: 0.5rem;
    isolation: isolate;
    display: grid;
    place-items: center;
    cursor: pointer;
    > {
      span {
        position: absolute;
        inset: 0;
        block-size: 100%;
        inline-size: 100%;
      }
      input:focus ~ span {
        //box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000;
      }
    }
    &[for*="size"] > {
      span {
        @include font-size(0.7rem);
        line-height: 1;
        display: grid;
        place-items: center;
        padding: 0.5rem;
        background: $border-light;
        border-radius: $border-radius;
      }
      input:checked ~ span {
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
      }
    }
    &[for*="color"] {
      inline-size: 1.5rem;
      block-size: 1.5rem;
      > {
        span {
          font-size: 1px;
          display: grid;
          place-items: center;
          background: var(--color, $border-light);
          border-radius: 100%;
          color: transparent;
        }
        input:checked ~ span {
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--color);
        }
      }
    }
    &[for*="image"] {
      inline-size: 140px;
      block-size: 100px;
      place-items: flex-start;
      margin-bottom: 20px;
      > {
        span.image {
          width: 135px;
          height: 100px;
          position: relative;
          overflow: hidden;
          border-radius: $border-radius;
          block-size: 100px;
          border: solid 5px transparent;
          transition: all 0.25s ease;
          img {
            @include imageBg();
            position: absolute !important;
            left: 0;
            width: 100% !important;
            height: 100% !important;
          }
        }
        span.sub {
          top: 100%;
          padding-top: 5px;
          block-size: 26px;
          font-weight: 600;
          font-size: 13px;
          padding-left: 5px;
          transition: all 0.25s ease;
        }
        input:checked ~ span.image {
          border-color: var(--#{$prefix}primary);
        }
        input:checked ~ span.sub {
          color: var(--#{$prefix}primary);
        }
      }
    }
  }
}
.form-floating > label {
  z-index: 4;
}

label.legal-label {
  line-height: 1.1;
  font-size: 14px;
  p {
    margin: 0;
  }
}
.newsletter-wrapper {
  .form-check-label {
    line-height: 1.1;
    font-size: 14px;
    p {
      margin: 0;
    }
  }
  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: inherit;
  }
  .form-check-input.is-valid:checked,
  .was-validated .form-check-input:valid:checked {
    background-color: $form-check-input-checked-bg-color;
  }
  .form-check-input.is-valid,
  .was-validated .form-check-input:valid {
    border-color: $form-check-input-checked-border-color;
  }
}
.form-check-input {
  border-color: rgba(var(--#{$prefix}dark-rgb), 0.3);
}
.form-check-input:focus {
  border-color: rgba(var(--#{$prefix}dark-rgb), 0.3);
}

.picker label[for*="color"].disabled > span {
  opacity: 0.5;
  cursor: not-allowed;
  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: calc(10px + 100%);
    background: $gray-600;
    top: -5px;
    z-index: 10;
    transform: rotate(45deg);
  }
}

.picker label[for*="size"].disabled > span {
  opacity: 0.5;
  cursor: not-allowed;
  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: calc(10px + 100%);
    background: rgba($gray-600, 0.5);
    top: -5px;
    z-index: 10;
    transform: rotate(45deg);
  }
}

.picker label[for*="color"],
.picker label[for*="size"] {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
form {
  textarea[rows] {
    height: auto !important;
  }
}

.cform_upl_preview {
  font-size: 15px;
  font-weight: 600;
  a {
    margin-right: 8px;
    i {
      font-size: 20px;
    }
  }
}
.cform_upload_inner {
  .cform_upl_preview_input {
    position: absolute;
    left: -9999px;
    height: 0;
  }
  .panel-upload {
    display: block;
    width: 100%;
    position: relative;
  }

  .cform_uplbtn-area {
    z-index: 20;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .panel-upload-dropzone {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .panel-upload-dropzone {
    border: 2px dashed rgba(var(--bs-dark-rgb), 0.2);
    border-radius: $border-radius;
    padding: 30px;
    &.invalid-field {
      border: 2px dashed $danger;
    }
  }

  .panel-upload-help-message {
    line-height: 13px;
    font-size: 11px;
    color: #777;
    padding-bottom: 5px;
  }

  .panel-upload-message {
    line-height: 13px;
    font-size: 11px;
    color: #777;
    padding-top: 5px;
  }

  .panel-upload-message.error {
    color: #cd3646;
  }

  .btn-uploader.disabled {
    display: none;
  }

  .cform_upl-progress {
    border-radius: 6px;
    background: rgba(#cacaca, 0.6);
    overflow: hidden;
    width: 100%;
    height: 15px;
    .cform_upl-progressbar {
      background: $primary;
      width: 100%;
      height: 15px;
    }
  }
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: inherit;
  background-image: none;
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: inherit;
  box-shadow: inherit;
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: inherit;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: #{escape-svg($form-select-indicator)};
  background-position: right 0.75rem center;
  background-size: 20px 20px;
  padding: 0.6rem 2rem 0.6rem 1rem;
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: inherit;
  box-shadow: inherit;
}
